<template>
    <div id="page-camera-list">
        <div class="vx-card p-6">
            <div class="flex flex-wrap items-center">
                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                          v-model="searchQuery"
                          @input="updateSearchQuery" placeholder="Tìm kiếm..."/>
                <vs-button
                    @click="addConfiguration"
                    color="primary"
                    type="border"
                    icon-pack="feather"
                    icon="icon-plus">
                    Thêm cấu hình
                </vs-button>
            </div>
            <div slot="no-body" class="mt-4">
                <vs-table :max-items="5" pagination :data="reportList" class="table-dark-report">
                    <template slot="thead">
                        <vs-th class="text-center">Ảnh</vs-th>
                        <vs-th class="text-center">Tên xâm nhập</vs-th>
                        <vs-th class="text-center">Tên camera</vs-th>
                        <vs-th class="text-center">Thời gian</vs-th>
                        <vs-th class="text-center">Action</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td>
                                <img style="width: 80px; height: 80px" :src="data[indextr].imageUrl"/>
                            </vs-td>
                            <vs-td :data="data[indextr].employeeName">
                                <span>{{ data[indextr].employeeName }}</span>
                            </vs-td>
                            <vs-td :data="data[indextr].cameraName">
                                <span>{{ data[indextr].cameraName }}</span>
                            </vs-td>
                            <vs-td :data="data[indextr].displayCreatedAt">
                                <span>{{ data[indextr].displayCreatedAt }}</span>
                            </vs-td>
                            <vs-td>
                                <vs-button
                                    @click="viewDetail(data[indextr].displayCreatedAt, data[indextr].cameraName, data[indextr].employeeName, data[indextr].imageUrl)"
                                    type="border" color="primary">Chi tiết
                                </vs-button>
                                <vs-button class="ml-2" @click="showCamera(data[indextr].cameraId)" type="border"
                                           color="primary">camera
                                </vs-button>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </div>
        <vs-popup title="Thêm cấu hình" :active.sync="popupActive">
            <vs-tabs color="primary">
                <vs-tab label="Cảnh báo khác">
                    <div class="wrap-pir">
                        <div class="title">
                            Cảnh báo PIR
                        </div>
                        <div class="content-inner">
                            <vs-checkbox v-model="checkBox1">Bật cảnh báo PIR</vs-checkbox>
                            <vs-input label="Tên cảnh báo" placeholder="" v-model="value1"/>
                            <div class="wrap-lienket">
                                <div class="title">
                                    <div class="inner">
                                        liên kết bình thường
                                    </div>
                                    <div class="inner">
                                        liên kết khác
                                    </div>
                                </div>
                                <div class="content">
                                    <div class="inner">
                                        <vs-checkbox v-model="checkBox2">Âm cảnh báo</vs-checkbox>
                                        <vs-checkbox v-model="checkBox3">Thông báo trung tâm</vs-checkbox>
                                        <vs-checkbox v-model="checkBox4">Gửi email</vs-checkbox>
                                        <vs-checkbox v-model="checkBox5">Tải lên FPT</vs-checkbox>
                                        <vs-checkbox v-model="checkBox6">Kích hoạt kênh</vs-checkbox>
                                    </div>
                                    <div class="inner last">
                                        <span style="float: left">Kích hoạt cảnh báo ngõ ra</span>
                                        <vs-checkbox v-model="checkBox7" style="float: left;margin: 0 0 0 15px">Chọn tất
                                            cả
                                        </vs-checkbox>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wrap-calendar mt-6">
                        <div class="title">
                            Lịch cảnh báo
                        </div>
                        <div class="inner-content">
                            <vs-button
                                class="mt-4 float-right"
                                @click="addConfiguration"
                                color="primary"
                                type="border"
                                icon-pack="feather">
                                Chỉnh Sửa
                            </vs-button>
                        </div>
                    </div>
                </vs-tab>
            </vs-tabs>
        </vs-popup>
    </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import moment from "moment";

export default {
    components: {
        AgGridVue,
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            popupActive: false,
            checkBox1: true,
            checkBox2: false,
            checkBox3: true,
            checkBox4: false,
            checkBox5: true,
            checkBox6: false,
            checkBox7: false,
            value1: '',
            reportList: [],
            branchId: null,
        }
    },
    created() {
        this.loadListStranger();
        setInterval(() => {
            this.loadListStranger();
        }, 30000)
    },
    watch: {},
    computed: {},
    methods: {
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        onGridReady() {
            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        addConfiguration() {
            this.popupActive = true;
        },
        viewDetail(date, cameraName, employeeName, imageUrl) {
            this.$router.push(`/user/detail-stranger?date=${date}&cameraName=${cameraName}&employeName=${employeeName}&imageUrl=${imageUrl}`).catch(() => {
            })
        },
        showCamera(id) {
            this.$router.push(`/user/manage-stranger/show?id=${id}`).catch(() => {
            })
        },
        loadListStranger() {
            this.reportList = [];
            this.$processorDemo.get('api/v1.1/intrusions/1').then((response) => {
                if (response.data != '') {
                    this.reportList.push(...response.data.reduce((arr, item) => {
                        item.imageUrl = "https://api.intface.vn/api/v1.1/intrusion/storage/1?name=" + item.imageUrl;
                        item.displayCreatedAt = moment(item.createdDate).format("DD/MM/YYYY HH:mm:ss");
                        item.employeeName = 'NoName';
                        item.cameraName = 'Camera 2';
                        arr.push({...item});
                        return arr;
                    }, []));
                }
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    }
}
</script>

<style>
.vs-table--tbody-table > tr:nth-child(2n) {
    background: #f8f8f8;
}

/*.table-dark-report .vs-con-tbody{
  background: none;
  border: none;
}*/
#page-camera-list .vx-card {
    z-index: 0;
}

.wrap-pir .vs-con-input-label {
    display: flex;
}

.wrap-lienket .inner {
    width: 50%;
    float: left;
}

.wrap-pir .title, .wrap-calendar .title {
    background: rgba(0, 0, 0, .05);
    padding-left: 10px;
    line-height: 38px;
}

.wrap-pir .content-inner .con-vs-checkbox {
    margin-left: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

.wrap-pir .wrap-lienket .title {
    display: flex;
    text-align: center;
}

.wrap-pir .wrap-lienket .content .inner {
    border: 1px solid #dedede;
    padding: 15px;
    border-top: none;
}

.wrap-pir .wrap-lienket .content .inner.last {
    border-left: none;
}

.wrap-pir .vs-input--label {
    white-space: nowrap;
    line-height: 38px;
    padding-left: 0;
    padding-right: 30px;
    font-size: 16px;
}

.wrap-pir .vs-con-input {
    min-width: 300px;
}

.wrap-pir .vs-con-input-label {
    margin-bottom: 10px;
}

.wrap-pir .wrap-lienket .content {
    display: flex;
}
</style>
